import { Link } from "gatsby";
import React from "react";
import ehumaboa from "../images/eh_uma_boa.jpeg";
import logoNavbar from "../images/logo_navbar.png";

function Header() {
  return (
    <nav className="navbar navbar-dark bg-primary shadow py-0">
      <div className="container-fluid ps-0">
        <Link to="/" className="navbar-brand bg-white px-4">
          <img
            src={logoNavbar}
            alt="logo-navbar"
            className="img-fluid"
            width="100"
          />
        </Link>
        <span className="d-none d-md-inline-block">
          mais de 8 milhões já usaram o anurp!
        </span>
      </div>
    </nav>
  );
}

function ContentAds() {
  return (
    <div className="col-12 col-lg-7 border-bottom border-bottom-lg-0 border-end-lg">
      <p className="fs-1 fw-bold text-center mt-4">Prometeu emagrecer?</p>
      <p className="fs-1 text-center">
        Emagreça de forma rápida e saudável com o ANURP!
      </p>
      <p className="fs-5 text-center">
        Faça sua a avaliação gratuita e veja como emagrecer com a estratégia low
        carb + jejum intermitente do ANURP
      </p>
      <div>
        <img className="img-fluid rounded" src={ehumaboa} alt="ehumaboa" />
      </div>
      <p className="text-center fs-6 text-muted">
        *os resultados no emagrecimento podem variar de pessoa para pessoa.
      </p>
    </div>
  );
}

function ContentForm() {
  return (
    <div className="col-12 col-lg-5 bg-white d-flex flex-column justify-content-center pt-lg-0 pt-4">
      <p className="text-center fw-bold fs-4">
        Preencha as informações e descubra o passo a passo para emagrecer:
      </p>
      <form>
        <div className="mb-3 row">
          <label htmlFor="id_peso" className="col-sm-3 col-form-label fw-bold">
            seu peso
          </label>
          <div className="col-sm-9">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control border-2 border-end-0"
                id="id_peso"
              />
              <span className="input-group-text bg-white border-2 border-start-0">
                kg
              </span>
            </div>
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="id_altura"
            className="col-sm-3 col-form-label fw-bold"
          >
            altura
          </label>
          <div className="col-sm-9">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control border-end-0 border-2"
                id="id_altura"
              />
              <span className="input-group-text bg-white border-2 border-start-0">
                m
              </span>
            </div>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="id_sexo" className="col-sm-3 col-form-label fw-bold">
            sexo
          </label>
          <div className="col-sm-9">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="sexo"
                id="id_masculino"
                value="masculino"
              />
              <label className="form-check-label" htmlFor="id_masculino">
                masculino
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="sexo"
                id="id_feminino"
                value="feminino"
              />
              <label className="form-check-label" htmlFor="id_feminino">
                feminino
              </label>
            </div>
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="id_meta_peso"
            className="col-sm-3 col-form-label fw-bold"
          >
            meta de peso
          </label>
          <div className="col-sm-9">
            <select className="form-select border-2" defaultValue="1">
              <option value="0">75 kg</option>
              <option value="1">76 kg</option>
              <option value="2">77 kg</option>
              <option value="3">78 kg</option>
            </select>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="id_nome" className="col-sm-3 col-form-label fw-bold">
            nome
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control border-2"
              placeholder="digite seu nome"
              id="id_nome"
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="id_email" className="col-sm-3 col-form-label fw-bold">
            e-mail
          </label>
          <div className="col-sm-9">
            <input
              type="email"
              className="form-control border-2"
              placeholder="digite seu e-mail"
              id="id_email"
            />
          </div>
        </div>

        <div className="d-grid">
          <button className="btn btn-primary fw-bold text-white">
            Ver resultado
          </button>
        </div>
      </form>
      <p className="fs-7 mt-4 fw-bold">
        Ao concluir, você aceita receber e-mails com dicas do ANURP e concorda
        com nossa Política de Privacidade.
      </p>
    </div>
  );
}

function Footer() {
  return (
    <footer className="bg-light p-4 mt-4">
      <div className="text-center">
        <img src={logoNavbar} alt="logo footer" height="100" />
      </div>
      <div className="container mt-2">
        <p className="text-muted fs-7">
          Os resultados podem variar de pessoa para pessoa e não são garantidos.
          As informações e sugestões contidas neste site não substituem o
          aconselhamento e o acompanhamento de médicos, nutricionistas,
          psicólogos, profissionais de educação física e outros especialistas.
        </p>
      </div>
    </footer>
  );
}

export default function Home() {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="row bg-light border-bottom">
          <ContentAds />
          <ContentForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}
